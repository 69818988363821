.homeApproved {
  /* border: solid red; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  width: 100%;
  /* background-color: var(--customColor3); */
}
.HomeApprovedCard {
  border: solid 1px var(--customColor2);
  border-bottom: 5px solid var(--customColor2);
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.53);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 100%;
  /* transform: scale(0.5); */
}
.HomeApprovedCard > a {
  text-decoration: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.HomeApprovedCard > a > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.HomeApprovedCard > a > p {
  text-align: center;
  color: white;
  width: 100%;
  font-size: 14px;
}
.homeApproved > img {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeApproved {
    gap: 15px;
  }
}
