@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
}
:root {
  --customColor1: #0c2d50;
  /* --customColor2:#E89E26; */
  --customColor2: #c30c1a;
  --customColor3: #fff6f4;
}
