.homeEnquiry {
  width: 100%;
  height: 100%;
  background-color: white;
  /* border: solid pink; */
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeEnquiryContact > h2 {
  color: var(--customColor1);
  font-size: 40px;
  width: 100%;
}
.homeEnquiryContent {
  /* border: solid green; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.homeEnquiryContent > img {
  width: 100%;
  transform: rotateY(180deg);
}
.homeEnquiryContact {
  /* border: solid red; */
  width: 100%;
  height: 100%;
}
.homeEnquiryContact-Content {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
}
.homeEnquiryContactCard {
  border-left: 5px solid var(--customColor2);
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 20px;
  box-shadow: 0 0 5px grey;
  cursor: pointer;
}
.homeEnquiryIcon {
  color: var(--customColor1);
  font-size: 20px;
}
#homeEnquiryContactCard-whats {
  border-left: 5px solid var(--customColor2);
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 20px;
  box-shadow: 0 0 5px grey;
  cursor: pointer;
}
#homeEnquiryContactCard-whats > a {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
/* ---------------------------homeEnquiryForm------------------------ */
.homeEnquiryForm {
  /* border: solid orange; */
  width: 100%;
  height: 100%;
  display: flex;
  visibility: hidden;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.434);
  top: 0;
  right: 0;
  backdrop-filter: blur(10px);
  overflow-y: scroll;
  z-index: 10;
}
.homeEnquiryForm > div {
  width: 40%;
  text-align: end;
  padding: 10px;
}
.homeEnquiryFormIcon {
  color: var(--customColor2);
  cursor: pointer;
  font-size: 30px;
}
.homeEnquiryForm > form {
  border-top: solid 5px var(--customColor2);
  box-shadow: 0 0px 10px rgba(255, 0, 0, 0.499);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  width: 400px;
  background-color: var(--customColor1);
}
.homeEnquiryForm > form > input {
  border: none;
  outline: none;
  border-bottom: solid 2px black;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}
.homeEnquiryForm > form > select {
  border: none;
  outline: none;
  border-bottom: solid 2px black;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}
.homeEnquiryForm > form > textarea {
  border: none;
  outline: none;
  border-bottom: solid 2px black;
  width: 100%;
  padding: 5px;
}
.homeEnquiryFormBtn > button {
  padding: 5px;
  font-weight: bold;
  color: white;
  background-color: var(--customColor2);
  border-radius: 5px;
  border: solid var(--customColor1);
}
.enquirySocial {
  /* border: solid red; */
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.enquirySocial > h4 {
  color: var(--customColor2);
}
.footerContactContent {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.footerContactContent > li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
.footerContactContent > li > a {
  color: var(--customColor2);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.enquiryContactIco {
  color: var(--customColor1);
}
.privacy {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  color: white;
  font-size: 12px;
  text-align: justify;
  padding: 10px;
}
.privacy > input {
  margin-top: 8px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeEnquiry {
    flex-direction: column-reverse;
    gap: 20px;
    padding: 10px;

  }
  .homeEnquiryContent {
    /* border: solid green; */
    width: 100%;
  }
  .homeEnquiryContact > h2 {
    color: var(--customColor1);
    font-size: 40px;
    width: 100%;
    text-align: center;
  }
  .homeEnquiryForm > form {
    border-top: solid 5px var(--customColor2);
    box-shadow: 0 0px 10px rgba(255, 0, 0, 0.499);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    width: 350px;
  }

  .enquirySocial {
    width: 100%;
  }
}
