.homePage {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: 100%;
  margin-top: 10px;
    /* background-color: var(--customColor3); */
    background-color: white;
}
