.homeCourses {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  background-color: white;
  padding: 20px;
}
.homeCoursesHead {
  width: 90%;
}
.homeCoursesHead > h2 {
  /* border: solid; */
  text-align: center;
  font-size: 40px;
  width: 100%;
  color: var(--customColor1);
}
.homeCoursesHead > p {
  font-size: 18px;
  text-align: center;
  color: grey;
  width: 100%;
}
.homeCoursesMain {
  /* border: solid green; */
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.homeCoursesCard {
  border-left: 6px solid var(--customColor2);
  /* border-radius: 10px; */
  box-shadow: 0 0 5px grey;
  padding: 10px;
  width: 300px;
  margin: 10px;
  position: relative;
  overflow: hidden;
}

.homeCoursesCard > a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--customColor1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.homeCoursesCard > a > h6 {
  text-align: center;
}
.homeCoursesCard > a > img {
  width: 30%;
  height: 40%;
  object-fit: contain;
}
@media screen and (min-width:768px) and (max-width:990px) {
  .homeCoursesMain {
    /* border: solid green; */
    width: 100%;
  }
}